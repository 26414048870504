<script setup lang="ts">
import { marked } from 'marked'

const props = defineProps<{
  content: string
}>()

const content = props.content ? marked.parse(props.content) : ''

const proseClasses = [
  // Headings
  'prose-headings:font-semibold',
  'prose-headings:text-muted-900',
  'prose-headings:dark:text-muted-300',
  // Other text (use generic classes to let them apply to all prose content)
  'text-muted-700',
  'dark:text-muted-400',
  // Custom size
  'md:text-md17',
]
</script>

<template>
  
  
  <BaseProse :class="proseClasses" class="">
    <div v-html="content" />
  </BaseProse>
</template>
